import { Modal, Group, Button } from '@mantine/core';
import useLogout from '../../hooks/useLogout';

const LogoutModal = ({ openLogout, close }) => {

    const logout = useLogout();

    const signout = async () => {
        await logout();
        close();
    }

    return (
        <>
            <Modal
                opened={openLogout}
                onClose={close}
                centered
                size={280}
                withCloseButton={false}
                className='font-bold'
                classNames={{ content: 'bg-[#fff]', content: 'z-[100000]', body: 'z-[10000]'}}
            >
                <div className='flex flex-col justify-center font-[poppins]'>

                    <div className='flex justify-center mb-8 mt-4 text-[15px] font-[500] font-[poppins] text-[#333333]'>
                        Do you wish to log out?
                    </div>

                    <Group position="center">
                        <Button 
                            onClick={close} 
                            variant="outline" 
                            radius='xl'
                            
                            classNames={{ 
                                label: 'px-2 text-[#333333] opacity-60',
                                root: 'border-[#333333]'
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={signout}
                            radius='xl'
                            className='font-bold'
                            classNames={{ root: 'bg-[#5BC17F] hover:bg-[#5BC17F] hover:opacity-90' }}
                        >
                            Log out
                        </Button>

                    </Group>
                </div>
            </Modal>
        </>
    );
}

export default LogoutModal