import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import useWindowDimensions from '../../../hooks/getWindowDimensions';

const NavbarLayout = ({ active }) => {

    const windowDimensions = useWindowDimensions();

    return (
        <div className='sticky top-0 z-[101]'>
            {windowDimensions.width >= 768 && 
                <NavbarDesktop 
                    active={active}
                />
            }
            {windowDimensions.width < 768 && 
                <NavbarMobile />
            }
        </div>
    );
}

export default NavbarLayout
