import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogout, IconFileDatabase, IconSettings, IconDashboard } from '@tabler/icons-react';
import LogoutModal from '../LogoutModal';
import { UserAvatarStyles as useStyles } from '../LayoutStyles/LayoutStyles';

const UserAvatar = ({ user }) => {
    const { theme } = useStyles();
    const [openLogout, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();

    let userInitial = (user.firstName.substring(0, 1)).toUpperCase();

    return (
        <div className=''>
            <Menu
                width={260}
                zIndex={401}
                position="bottom-end"
                transitionProps={{ transition: 'pop-top-right' }}
                withinPortal
            >
                <Menu.Target>
                     <div className='cursor-pointer '>
                        <div className='flex flex-row items-center gap-2'>
                            <div
                                className='flex items-center justify-center hover:opacity-80 bg-[#fff] w-[42px] h-[42px]  rounded-full text-[#5BC17F] font-bold font-[poppins] text-xl' 
                            >
                                {userInitial}
                            </div>
                            
                        </div>
                    </div>
                </Menu.Target>

                <Menu.Dropdown className='shadow-nav'>
                    <Menu.Item className='font-[500] font-[poppins] text-[#404040]'>
                        Hey {[user.firstName, '!']}
                    </Menu.Item>
                    <Menu.Item
                        icon={<IconDashboard size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                        onClick={() => navigate(`/profile/${user.id}/dashboard`)}
                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                    >
                        My Dashboard
                    </Menu.Item>
                    <Menu.Item
                        icon={<IconFileDatabase size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                        onClick={() => navigate(`/profile/${user.id}/data+profiles`)}
                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                    >
                        My Reports
                    </Menu.Item>

                    <Menu.Divider />

                    <Menu.Item
                        icon={<IconLogout size="1.2rem" stroke={1.5} color={theme.colors.red[6]} />}
                        onClick={open}
                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                    >
                        Log out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <LogoutModal
                openLogout={openLogout}
                close={close}
            />
        </div>
    )
}

export default UserAvatar