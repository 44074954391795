import { Group, Button, UnstyledButton, ThemeIcon, Text, HoverCard, Box, Center, Divider } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconCode, IconCoin, IconChevronDown } from '@tabler/icons-react';
import DesktopUserAvatar from './DesktopUserAvatar';
import useAuth from '../../../hooks/useAuth';

const NavbarDesktop = ({ active }) => {
    const { auth } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();

    const menuItems = [
        {
            name: 'Platform',
            link: ''
        },
        {
            name: 'Pricing',
            link: '/pricing/plans'
        },
        {
            name: 'Contact',
            link: '/contact'
        },
    ]

    const mockdata = [
        {
            icon: IconCode,
            title: 'Report API',
            description: 'Generate custom market report deliveriables',
            link: '/platform/reports/api'
        },
        {
            icon: IconCoin,
            title: 'Demographic API',
            description: 'Access raw demographic data',
            link: '/platform/demographic/api'
        }
    ]

    const links = mockdata.map((item) => (
        <UnstyledButton
            className='flex w-full rounded-md font-[poppins] hover:opacity-80'
            key={item.title}
            onClick={() => navigate(item.link)}
        >
            <div className='w-full flex flex-row gap-4 items-center'>

                <ThemeIcon size={34} variant="default" radius="md">
                    <item.icon style={{ width: '24px', height: '24px' }} color='#5BC17F' />
                </ThemeIcon>

                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" c="dimmed">
                        {item.description}
                    </Text>
                </div>
            </div>
        </UnstyledButton>
    ));

    return (
        <div className={`flex py-[24px] px-[80px] justify-center items-center self-stretch ` + (location.pathname !== '/platform/demographic/api' &&  'bg-[#FFFFFF]')}>

            <div className='flex max-w-[1000px] w-full justify-between items-start py-[12px] px-[24px] bg-[#5BC17F] shadow-nav rounded-xl'>

                <div className='flex flex-row gap-[38px] self-center'>

                    <Group>
                        <div
                            className='font-bold text-2xl text-white hover:cursor-pointer font-[poppins]'
                            onClick={() => navigate('/')}
                        >
                            DashPond
                        </div>
                    </Group>

                    <div className='flex flex-row items-center h-full gap-[16px] self-center'>
                        {menuItems.map((item, index) => (
                            <div>
                                {item.name === 'Platform' &&
                                    <HoverCard width={500} position="bottom" radius="lg" withinPortal classNames={{ dropdown: 'shadow-nav' }}>
                                        <HoverCard.Target>
                                            <a href="#" className='flex no-underline text-white font-[poppins] tracking-wide text-sm subpixel-antialiased '>
                                                <Center inline>
                                                    <Box component="span">
                                                        Platform
                                                    </Box>
                                                    <IconChevronDown
                                                        style={{ width: '16px', height: '16px' }}
                                                        color='#fff'
                                                    />
                                                </Center>
                                            </a>
                                        </HoverCard.Target>

                                        <HoverCard.Dropdown style={{ overflow: 'hidden' }} className='bg-[#fff] font-[poppins]'>
                                            <Group position="apart">
                                                <Text fw={500}>
                                                    Products
                                                </Text>
                                            </Group>

                                            <Divider my="sm" />

                                            <div className='w-full flex flex-row justify-between'>
                                                {links}
                                            </div>

                                            {!auth.accessToken &&
                                                <div className='flex flex-row w-full justify-between mt-4'>

                                                    <div>
                                                        <Text fw={500} fz="sm">
                                                            Get started
                                                        </Text>
                                                        <Text size="xs" c="dimmed">
                                                            Create an account and get 5 free reports
                                                        </Text>
                                                    </div>
                                                    <div
                                                        className={`flex w-[98px] justify-center text-white items-center rounded-3xl bg-[#5BC17F]
                                                                hover:font-[500] font-[400] text-sm hover:cursor-pointer`}
                                                        onClick={() => navigate('/register')}
                                                    >
                                                        Get started
                                                    </div>
                                                </div>
                                            }
                                        </HoverCard.Dropdown>
                                    </HoverCard>
                                }
                                {item.name !== 'Platform' &&
                                    <div
                                        key={index}
                                        className={index + 1 === active
                                            ? 'hover:cursor-pointer text-white font-[poppins] tracking-wide text-sm subpixel-antialiased underline underline-offset-8 decoration-2'
                                            : 'hover:cursor-pointer text-white font-[poppins] tracking-wide text-sm subpixel-antialiased hover:underline hover:underline-offset-8 decoration-2'}
                                        onClick={() => navigate(item.link)}
                                    >
                                        {item.name}
                                    </div>
                                }
                            </div>
                        ))}
                    </div>

                </div>


                <div className='font-[poppins]'>
                    {!auth?.accessToken
                        ? <div>
                            <div className='flex flex-row items-center gap-4'>
                                <div
                                    onClick={() => navigate('/login')}
                                    className=' text-white py-[18px] px-[8px] 
                                    hover:cursor-pointer font-[500] text-sm subpixel-antialiased hover:underline hover:underline-offset-8 decoration-2'
                                >
                                    Login
                                </div>

                                <Button
                                    onClick={() => navigate('/register')}
                                    variant="filled"
                                    radius={40}
                                    className='bg-[#fff] font-semibold text-[#5BC17F] '
                                    classNames={{ label: 'font-bold', root: 'bg-[#3C3795] hover:bg-[#fff] hover:opacity-80' }}
                                >
                                    Sign up
                                </Button>
                            </div>
                        </div>
                        : <div>
                            <DesktopUserAvatar
                                user={auth}
                            />
                        </div>}
                </div>
            </div>

        </div>
    )
}

export default NavbarDesktop