import { Menu, Burger } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import useAuth from '../../../hooks/useAuth';
import { IconDashboard, IconFileDatabase, IconLogin, IconCirclePlus, IconMailFast, IconCash, IconApiApp, IconSettings } from '@tabler/icons-react';
import LogoutModal from '../LogoutModal';

const NavbarMobile = () => {

    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [openLogout, { open, close }] = useDisclosure(false);


    return (
        <div className={`flex py-[24px] px-[40px] justify-center items-center self-stretch ` 
                + (location.pathname !== '/platform/demographic/api' || location.pathname !== '/platform/demographic/api'  &&  ' bg-[#FFFFFF]')}>

            <LogoutModal
                openLogout={openLogout}
                close={close}
            />

            <div className='flex w-[1000px] justify-between items-center py-[12px] px-[24px] bg-[#5BC17F] rounded-xl'>

                <div className='flex flex-row gap-[38px] items-center justify-between w-full'>

                    <div
                        className='flex font-bold text-xl text-white hover:cursor-pointer h-[100%] items-center'
                        onClick={() => navigate('/')}
                    >
                        DashPond
                    </div>

                    <Menu
                        width={260}
                        zIndex={401}
                        position="bottom-end"
                        transitionProps={{ transition: 'pop-top-right' }}
                        withinPortal
                    >
                        <Menu.Target>
                            <Burger color='white' />
                        </Menu.Target>

                        <Menu.Dropdown className='shadow-nav'>
                            <Menu.Label>Navigation</Menu.Label>

                            <Menu.Item
                                className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                icon={<IconApiApp size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                            >
                                Platform
                            </Menu.Item>
                            <Menu.Item
                                className='px-8 text-[14px] font-[400] text-[#404040] font-[poppins]'
                                onClick={() => navigate(`/platform/reports/api`)}
                            >
                                Reports API
                            </Menu.Item>
                            <Menu.Item
                                className='px-8 text-[14px] font-[400] text-[#404040] font-[poppins] hover:bg-[#1C00ff00'
                                onClick={() => navigate(`/platform/demographic/api`)}
                            >
                                Demographic API
                            </Menu.Item>
                            <Menu.Item
                                className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                icon={<IconCash size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                onClick={() => navigate(`/pricing/plans`)}
                            >
                                Pricing
                            </Menu.Item>
                            <Menu.Item
                                className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                icon={<IconMailFast size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                onClick={() => navigate(`/contact`)}
                            >
                                Contact
                            </Menu.Item>

                            {auth.accessToken ?
                                <div>
                                    <Menu.Divider />

                                    <Menu.Label>Account</Menu.Label>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconDashboard size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => navigate(`/profile/${auth.id}/dashboard`)}
                                    >
                                        Dashboard
                                    </Menu.Item>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconFileDatabase size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => navigate(`/profile/${auth.id}/data+profiles`)}
                                    >
                                        Reports
                                    </Menu.Item>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconSettings size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => navigate(`/profile/${auth.id}/settings`)}
                                    >
                                        Settings
                                    </Menu.Item>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconSettings size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => open()}
                                    >
                                        Logout
                                    </Menu.Item>
                                </div>
                                :
                                <div>
                                    <Menu.Divider />

                                    <Menu.Label>Account</Menu.Label>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconLogin size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => navigate(`/login`)}
                                    >
                                        Login
                                    </Menu.Item>
                                    <Menu.Item
                                        className='text-[16px] font-[400] text-[#404040] font-[poppins]'
                                        icon={<IconCirclePlus size="1.2rem" stroke={1.5} className='text-[#5BC17F]' />}
                                        onClick={() => navigate(`/register`)}
                                    >
                                        Sign up
                                    </Menu.Item>
                                </div>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default NavbarMobile