import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, Button, Container, Group, Image } from '@mantine/core';

const AlreadyPaid = () => {
    const navigate = useNavigate();
    return (
        <Container className='pb-[80px] flex flex-col items-center justify-center'>
            <Image src='/images/404/404-orange.svg' className='text-center justify-center mb-4 max-w-[400px]' />
            <Title className='text-center font-[900] text-[38px] mb-4 max-w-[400px]'>
                Looks like You Already Have an Unlimited Plan!
            </Title>
            <Group position="center" className='mt-8'>
                <Button size="md" onClick={() => { navigate('/') }}>
                    Take me back to home page
                </Button>
            </Group>
        </Container>
    )
}

export default AlreadyPaid