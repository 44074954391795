import NavbarLayout from './Navbar/NavbarBlue';
import { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => {

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className='flex flex-col min-h-[100vh] bg-[#FFFFFF]'>
            <NavbarLayout 
                active={activeTab}
            />

            <div className='min-h-[calc(100vh-116px)] flex justify-center'>
                <Suspense>
                    <Outlet 
                        context={[activeTab, setActiveTab]}
                    />
                </Suspense>
            </div>
        </div>
    )
}

export default Layout

// display: 'flex',
// flexDirection: 'column',
// minHeight: '100vh',
// backgroundColor: '#0F172A'